<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a href="#" class="nav__btn nav__btn--prev">
				<i class="icon icon icon-left">{{t('10030')}}</i>
			</a>
		</div>
	</nav>
	<!-- //nav -->
	<div id="container" class="container">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub find">
			<!-- contact -->
			<section class="content">
				<div class="row">
					<div class="inner">
						<div class="inner__top">
							<h2 class="title">
								{{t('10225')}}
							</h2>
							<div class="inner__text">
								{{t('10226')}}
							</div>
						</div>
						<div class="inner__wrap">
							<div class="dormant__auth">
								<label>
									<div class="radio">
										<input type="radio" class="rad blind" name="isDormant">
										<span class="radio__bg"></span>
										<span class="dormant__authtype">
											H_SALAD@naver.com
										</span>
									</div>
								</label>
							</div>
							<div class="dormant__auth">
								<label>
									<div class="radio">
										<input type="radio" class="rad blind" name="isDormant">
										<span class="radio__bg"></span>
										<span class="dormant__authtype">
											H_SALAD@gmail.com
										</span>
									</div>
								</label>
							</div>
							<div class="dormant__auth">
								<label>
									<div class="radio">
										<input type="radio" class="rad blind" name="isDormant">
										<span class="radio__bg"></span>
										<span class="dormant__authtype">
											H_SALAD@daum.net
										</span>
									</div>
								</label>
							</div>
						</div>
					</div>
				</div>
			</section>
			<TheNavigation></TheNavigation>
			<!-- //content -->
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder">
		<div class="btns btns--centered" style="margin-bottom:-10px;padding-bottom:0;">
			<button type="button" class="btn btn-primary purple">{{t('10229')}}</button>
		</div>
		<div class="btns btns--centered">
			<button type="button" class="btn btn-confirm">{{t('10230')}}</button>
		</div>
	</div>
	<TheNavigation></TheNavigation>
</template>

<script>
import { computed } from '@vue/reactivity';
import { useStore } from 'vuex';
import { onMounted } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

export default {
	setup() {
		const store = useStore();
		const router = useRouter();
		const { t }= useI18n() //번역필수 모듈
	
		onMounted(() => {
			store.dispatch('mmb03/fetchMmb0302');
		})
		// const maskEmail = (email) => {
		// 	let split = email.split('@')
		// 	return split[0].slice(0, -3) + "xxx" + "@" + split[1]
		// }
		return {
			mmb0302: computed(() => store.state.mmb03.mmb0302),
			goLogin: () => {
				router.push("/layers/login");
			},
			goFindPw: () => {
				router.push("/member/find/password/step1");
			},
			//maskEmail,
			t,
			i18n,
		}
	},
}
</script>